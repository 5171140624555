<template>
    <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <Breadcrumb :title="$t('menu.project')"> </Breadcrumb>
            <el-container>
                <el-main style="padding:0; !important">
                    <el-container v-for="(project, index) in projects.datas" :key="index">
                        <el-main>
                            <el-container style="margin-bottom: 20px;">
                                <el-text class="title">{{ $t('project.' + project.key) }}</el-text>
                            </el-container>
                            <el-container v-for="(desc, i) in project.description" :key="i" style="margin-bottom: 20px;">
                                <el-text class="description">{{ $t('project.' + desc) }}</el-text>
                            </el-container>
                            <el-container>
                                <el-row>
                                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-for="(path, j) in project.images"
                                        :key="j">
                                        <el-image :src="path" fit="fill" :preview-src-list="project.images"
                                            :initial-index="j" :hide-on-click-modal="true"></el-image>
                                    </el-col>
                                </el-row>
                            </el-container>
                        </el-main>
                    </el-container>
                </el-main>
            </el-container>
        </el-col>
    </el-row>
</template>
    
<script>
import projects from "/public/Json/project.json";
import Breadcrumb from "../../../components/Breadcrumb.vue"

export default {
    name: "Common",
    components: {
        Breadcrumb
    },
    data() {
        return {
            projects
        };
    }
};
</script>
    
<style scoped>
:deep(.el-table__header-wrapper) {
    font-size: large;
}

:deep(.el-table--enable-row-hover .el-table__body tr:hover > td) {
    background: rgb(0, 111, 164) !important;
}

.title {
    font-size: large;
    font-weight: bold;
}

.description {
    text-indent: 2em;
}
</style>